
import {computed, defineComponent, onUpdated} from "vue";
import { LoadEntity, userRoleCheck} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import UserView from "@/components/base/common/UserView.vue";
import store from "@/store";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import TabNavigate from "@/components/base/common/TabNavigate.vue";


export default defineComponent({
  name: "Team",
  components: {TabNavigate, UserView},
  setup() {
    onUpdated(() => {

      setCurrentPageBreadcrumbsWithParams('Team Details', [
        {link: true, router: '/teams', text: 'Teams'},
        {link: false, router: '', text: 'View Team'}
      ])
    })
    const user = computed(() => store.getters.currentUser);
    const entity = computed(() => store.state.GroupModule.entity);
    return {
      entity,
      user,
      ...userRoleCheck(),
      ...LoadEntity(Actions.LOAD_GROUP)
    }
  },
  methods: {
    buildNav(entity) {
      const data = [
        {id: 'team-members', router: `/teams/` + entity.id + '/members', name: 'Members'},
      ]

      data.push({id: 'teamPermissions', router: `/teams/` + entity.id + '/permissions', name: 'Permissions'})

      data.push({id: 'teamLogins', router: `/teams/` + entity.id + '/logins', name: 'Logins'},
        {id: 'teamLogins', router: `/teams/` + entity.id + '/action-logs', name: 'Action Logs'},
        {id: 'teamAnalytics', router: `/teams/` + entity.id + `/analytics`, name: 'Analytics'})
      return data;
    }
  }
})
